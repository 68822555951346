.Carousel-Container{
    margin-top: 20px;
    width: 75vw;
}

.Coupon-Container:hover {
    box-shadow: 0 1px 20px #ff9d5c;
    border-radius: 15px;
    background: #f6f6f6;
}

.Coupon-Container {
    width: 100%;
    position: relative;
    margin: 18px;
    border-radius: 7px;
    border: thin solid rgb(224, 224, 224);
    overflow: hidden;
    background: #fff;
    box-shadow: rgb(0 0 0 / 5%) 0px 3px 8px;
    color: #4b4b4b;
    display: flex;
    flex-direction: column;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
}

.Image-Container {
    width: 100%;
    height: 100px;
    overflow: hidden;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border-radius: 7px 7px 0 0;
}

.Image-Container img {
    width: 60%;

}

.Coupon-Desc{
    position: relative;
    padding: 8px 15px;
    border-top: thin solid rgb(224, 224, 224);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
}

.Coupon-Cat{
    position: relative;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4b4b4b;
}

.Coupon-Title{
    position: relative;
    display: -webkit-box;
    min-height: 44px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.375em;
    font-size: 18px;
    font-weight: 900;
    color: rgb(50, 50, 50);
    text-align: left;
}

.Coupon-Time{
    position: relative;
    font-size: 11px;
    margin-bottom: 1px;
    color: rgb(50, 50, 50);
}

.swiper-button-next, .swiper-button-prev {
    color:  #ea5e2c;
}

@media screen and (max-width: 1465px){
    .Coupon-Title{
        -webkit-line-clamp: 2;
    }
}

@media screen and (max-width: 768px){
    .Coupon-Container {
        width: 80%;
    }
}
