.Home-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: auto;
}

.Home-Container h1{
    font-weight: bold;
    font-family: "Protest Riot", sans-serif;
    font-style: normal;
    color: orangered;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    text-align: center;
}

.Home {
    display: flex;
    width: 75vw;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.ant-menu {
    background-color:  #ea5e2c;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    justify-content: space-between;
    height: 70px;
}

.ant-menu-item {
    order: 0 !important;
}

.ant-menu::before, .ant-menu::after {
    content: none;
}

/*@media (min-width: 1050px){*/
/*    .Home {*/
/*        max-width: 1290px;*/
/*    }*/
/*}*/

/*@media (min-width: 992px){*/
/*    .Home {*/
/*        max-width: 960px;*/
/*    }*/
/*}*/

/*@media (min-width: 768px){*/
/*    .Home {*/
/*        max-width: 720px;*/
/*    }*/
/*}*/
/*@media (min-width: 576px){*/
/*    .Home{*/
/*        max-width: 720px;*/
/*    }*/
/*}*/

@media (min-width: 300px) and (max-width: 576px){
    .Home {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 720px) and (max-width: 1024px){
        .Home {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }
}


.Search-Result-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.Search-Container span{
    color: grey;
    font-size: 20px;
    font-width: bolder;
}

.Search-Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    margin: auto;
}

.Load-More{
    color: #f17c2f;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0 50px 0;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Load-More:hover {
    background-color: #f17c2f;
    color: white;
}

.Flags-Container{
    width: calc(100% + 29vw);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
}

.Flags-Container img{
    width: 50px;
    height: 50px;
    margin: 0 5px;
    cursor: pointer;
}

.Flags-Container .Current-Flag{
    border-radius: 25px;
    border: 2px solid #f17c2f;
}

.Brands-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 75vw;;
    margin-bottom: 20px;
}

.Brand-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    cursor: pointer;
    width:18%;
    height: 200px;
    border: 1px solid #f17c2f;
    border-radius: 4px;
}

.Brand-Title-Container{
    font-weight: bold;
    color: white;
    background: #f17c2f;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image-Container-Brand{
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    border-radius: 7px 7px 0 0;
}

.Image-Container-Brand img {
    display: inline-block;
    width: 60%;
}

@media screen and (max-width: 1465px){
    .Brand-Container{
        width: 17%;
    }
    .Coupon-Container-Home{
        width: 100%;
    }
    .Image-Container-Home {
        width: 20%;
    }
}

@media screen and (max-width: 478px){
    .Home-Container{
        width: 85vw;
    }

    .ant-menu{
        background: #f17c2f;
    }

    .ant-menu-item{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Brands-Container{
        justify-content: center;
    }

    .Brand-Container{
        width: 40%;
        height: 100px;
    }

    .Flags-Container{
        width: 100%;
    }

    .Flags-Container img{
        width: 25px;
        height: 25px;
    }

    .Search-Container {
        align-items: center;
    }

    .Search-Result-Container{
        width: 85vw;
    }
}